<div class="pdf-viewer-iframe">
  @if (iframeIndex !== -1)
  {
    <app-moon-pdf-viewer class="pdf-viewer"
      [src]="document"
      [optionalMoonFile]="optionalDocument"
      [pdfJsViewer]="true"
      [scrollPosition]="scrollPosition"
      [zoomLevel]="zoomLevel"
      [mouseListener]="mouseListenerMode"
      [customBottomToolbarButtons]="comparePdfViewerToolbarButtons"
      [viewerMode]="pdfViewerModeEnum.TextCompare"
      [optionalPdfFiles]="optionalPdfFiles"
      [allowSrcChange]="allowSrcChange"
      [enableAnnotations]="enableAnnotations"
      [subTaskMessages]="subTaskMessages"
      (adobeAnnotationDataCreated)="onAdobeAnnotationDataCreated($event)"
      (pdfViewerModeChange)="onPdfViewerModeChange($event)"
      (rotationChange)="onRotationChange()"
      (highlightClicked)="onHighlightClicked($event)"
      (allTextReaded)="onAllTextReaded($event)"
      (scrollChange)="onScrollChange($event)"
      (zoomLevelChange)="onZoomLevelChange($event)"
      (textSelected)="onTextSelected($event)"
      (zoomFactorChange)="zoomFactor = $event"
      (openMoonDocumentPicker)="onOpenMoonDocumentPicker()"
      (pdfChanged)="onPdfChanged()">
    </app-moon-pdf-viewer>
  }
  @else
  {
    <div class="busy">
      <mat-spinner diameter="32" color="accent" mode="indeterminate"></mat-spinner>
    </div>
  }

</div>
