import { Injectable, Inject } from '@angular/core';
import { AuthService, Progress } from './auth.service';
import * as _ from 'underscore';
import { ImageCompare, FileInfo, AlignmentAlgorithm } from '../_models/imageCompare/ImageCompare';
import { ImageCompareQuery } from '../_models/imageCompare/ImageCompareQuery';
import { PlatformInfoProvider } from '../_dependencies/platform-info-provider';
import { LoggingService, Severity } from './logging.service';
import { EventHubService } from './event-hub.service';
import { ImageOcrResult } from '../_models/imageCompare/ImageOcrResult';
import { DocumentVersion, ImageCompareResult } from '../public_api';
import { ImageCompareForSearchDto } from '../_modelsDTO/imageCompares/ImageCompareForSearchDto';
import { PagedResponse } from '../_models/api/PagedResponse';
declare var resemble: any;
declare var EXIF: any;

export interface PreloadedImageCompare
{
  documentVersion?: DocumentVersion;
  imageUrl?: string;
  pdfFile?: File;
}

interface ImageCompareRequest
{
  originalImageFileInfo: FileInfo;
  printImageFileInfo: FileInfo;
  companyId: string;
  scale: string;
  alignmentAlgorithm: AlignmentAlgorithm;
  skipAlignment: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ImageComparationService
{
  private icResultsPollingDelayMs = 6000;
  private icResultsPollingMaxAttempts = 25;

  backupOriginalSrc: string;
  backupPrintedSrc: string;

  backupUploadOriginalFile: File;
  backupUploadPrintedFile: File;

  backupUploadOriginalInfo: FileInfo;
  backupUploadPrintedInfo: FileInfo;

  //preloadedImageCompare: PreloadedImageCompare;

  constructor(
        @Inject('PlatformInfoProvider') private platformInfoProvider: PlatformInfoProvider,
        private authService: AuthService, private log: LoggingService, private eventHub: EventHubService)
  {
  }

  async searchImageComparesDto(query: ImageCompareQuery): Promise<PagedResponse<ImageCompareForSearchDto>>
  {
    const identity = this.authService.getCurrentIdentity();
    if (!identity || !identity.company)
    {
      return;
    }
    query.companyId = identity.company.id;

    if (query.minDate instanceof Date)
    {
      query.minDate = query.minDate.toISOString();
    }
    if (query.maxDate instanceof Date)
    {
      query.maxDate = query.maxDate.toISOString();
    }

    const response = await this.authService.authGetObjectParam<PagedResponse<ImageCompareForSearchDto>>(
      '/api/v2/imagecompare/searchImageCompares',
      query);
    return response;
  }

  async getImageCompare(imageCompareId: string): Promise<ImageCompare>
  {
    const params: [string, string][] = [
      ['imageCompareId', imageCompareId]
    ];
    const response = await this.authService.authGet<ImageCompare>('/api/imagecompare/getImageCompare', params);
    this.completeIcObject([response]);
    return response;
  }

  async getOcrResults(imageCompareId: string): Promise<ImageOcrResult>
  {
    const params: [string, string][] = [
      ['imageCompareId', imageCompareId]
    ];

    let attemptCount = 0;

    while (attemptCount < this.icResultsPollingMaxAttempts)
    {
      let result: ImageOcrResult;
      console.log('getting ocr results');
      result = await this.authService.authGet<ImageOcrResult>('/api/imagecompare/getICOcrResult', params);
      if (!result)
      {
        console.log('no ocr results yet');
        await this.delay(this.icResultsPollingDelayMs);
      }
      else
      {
        switch (result.state)
        {
        case 'Done':
          return result;
        case 'Requested':
          console.log('no ocr results yet');
          await this.delay(this.icResultsPollingDelayMs);
          break;
        case 'Error':
          throw new Error('Error getting OCR results. Please try again');
        }
      }
      attemptCount++;
    }
    throw new Error('The maximum number of attempts to obtain the ocr results was exceeded.');
  }


  async getDiffResults(imageCompareId: string): Promise<ImageCompareResult>
  {
    const params: [string, string][] = [
      ['imageCompareId', imageCompareId]
    ];

    let attemptCount = 0;

    while (attemptCount < this.icResultsPollingMaxAttempts)
    {
      let result: ImageCompareResult;
      console.log('getting rectangles results');
      result = await this.authService.authGet<ImageCompareResult>('/api/imagecompare/getICDiffResult', params);
      if (!result)
      {
        console.log('no rectangles results yet');
        await this.delay(this.icResultsPollingDelayMs);
      }
      else
      {
        switch (result.state)
        {
        case 'Done':
        case 'TooManyDifferences':
          return result;
        case 'Requested':
          console.log('no rectangles results yet');
          await this.delay(this.icResultsPollingDelayMs);
          break;
        case 'Error':
          throw new Error('Error getting rectangles results. Please try again');
        }
      }
      attemptCount++;
    }
    throw new Error('The maximum number of attempts to obtain the rectangles results was exceeded.');
  }

  async updateStatus(imageCompareId: string, status: 'Deleted' | 'Saved' | 'Starred')
  {
    const params: [string, string][] =
    [
      ['imageCompareId', imageCompareId],
      ['status', status]
    ];
    await this.authService.authGet<ImageCompare>('/api/imagecompare/updateStatus', params);
  }

  async updateImageCompare(ic: ImageCompare): Promise<ImageCompare>
  {
    const icCopy = JSON.parse(JSON.stringify(ic));
    if (icCopy.imageCompareConfig)
    {
      icCopy.imageCompareConfig.selectedImageOcrResultId = icCopy.imageCompareConfig.selectedImageOcrResult?.id;
      icCopy.imageCompareConfig.selectedImageOcrResult = undefined;
    }
    const result = await this.authService.authPost<ImageCompare>('/api/imagecompare/updateImageCompare', icCopy);
    this.completeIcObject([result]);
    return result;
  }

  async requestImageCompare(
    original: File,
    originalFileInfo: FileInfo,
    print: File,
    printFileInfo: FileInfo,
    skipAlignment?: boolean,
    scale?: 'small' | 'medium' | 'big',
    progressCallback?: (progress: Progress) => void,
    algorithm?: AlignmentAlgorithm): Promise<string>
  {
    const formData = new FormData();
    const identity = this.authService.getCurrentIdentity();
    const icRequest: ImageCompareRequest =
    {
      companyId: identity.company.id,
      originalImageFileInfo: originalFileInfo,
      printImageFileInfo: printFileInfo,
      scale: scale,
      alignmentAlgorithm: algorithm,
      skipAlignment: skipAlignment
    };
    formData.append('imagecomparerequest', JSON.stringify(icRequest));
    formData.append('file', original, 'original.' + original.name.split('.').pop());
    formData.append('file', print, 'print.' + print.name.split('.').pop());

    this.log.logHubspot_RequestImageCompare();

    const result = await this.authService.longPost<string>('/api/v2/imagecompare/requestImageCompare', formData, progressCallback);
    return result;
  }

  /**
   * Read the exif metadata of the given file.
   * Returns FileInfo or undefined in case of error.
   * Errors are logged to trace|Error and as a silent support message
   */
  getExifData(file: File): Promise<FileInfo>
  {
    return new Promise((res, rej) =>
    {
      try
      {
        EXIF.getData(file, () =>
        {
          try
          {
            let ext = '';
            const split = file.type.split('/');
            if (split.length > 1)
            {
              ext = `.${split[split.length - 1]}`;
            }
            const result: FileInfo =
            {
              name: file.name,
              filetype: ext,
              width: -1,
              height: -1,
              horizontalResolution: -1,
              verticalResolution: -1
            };
            const exifdata = (<any>file).exifdata;
            if (exifdata !== undefined)
            {
              result.horizontalResolution = exifdata.XResolution;
              result.width = exifdata.PixelXDimension;

              result.verticalResolution = exifdata.YResolution;
              result.height = exifdata.PixelYDimension;
            }
            res(result);
          }
          catch (err)
          {
            console.log(err);
            this.exifError('Unexpected EXIF error', err);
            res(undefined);
          }
        });
      }
      catch (err)
      {
        console.log(err);
        this.exifError('EXIF not available', err);
        res(undefined);
      }
    });
  }

  private exifError(msg: string, err?: any)
  {
    // we don't want to show this to the user through feedbackservice,
    // but it could be halpful in appInsights and the slack support
    this.log.trackTrace(msg, Severity.Error);
    this.eventHub.silentSupportMessage.emit({message: msg, error: err});
  }

  async requestOcr(imageCompareId: string): Promise<void>
  {
    const params: [string, string][] =
    [
      ['imageCompareId', imageCompareId]
    ];
    await this.authService.authGet<ImageOcrResult>('/api/imageCompare/requestOcr', params);
  }

  async requestPythonDiff(imageCompareId: string): Promise<void>
  {
    const params: [string, string][] =
    [
      ['imageCompareId', imageCompareId]
    ];
    await this.authService.authGet<ImageCompareResult>('/api/imageCompare/requestPythonDiff', params);
  }

  private completeIcObject(ics: ImageCompare[])
  {
    for (const ic of ics)
    {
      ic.timestampUtc = new Date(ic.timestampUtc);
      if (ic.inputFileInfos)
      {
        ic.originalFileInfo = _.find(ic.inputFileInfos, ifi => ifi.name.startsWith('original.'));
        ic.printFileInfo = _.find(ic.inputFileInfos, ifi => ifi.name.startsWith('print.'));
      }
    }
  }

  getImage(ic: ImageCompare, fileName: string)
  {
    if (!fileName)
    {
      return undefined;
    }
    const identity = this.authService.getCurrentIdentity();
    let token: string;
    if (identity.user)
    {
      token = identity.user.token;
    }
    const backendUrl = this.platformInfoProvider.getBackendUrl();
    const url = `${backendUrl}/api/imagecompare/getimage?imageCompareId=${ic.id}&fileName=${fileName}&access_token=${token}`;
    return url;
  }

  localImageCompare(imageSrc: string, imageToCompareSrc: string, errorColorHEX?: string): Promise<string>
  {
    let color =
    {
      red: 255,
      green: 0,
      blue: 0
    };
    if(errorColorHEX)
    {
      const hex = this.hexToRgb(errorColorHEX);
      color =
      {
        red : hex[0],
        green: hex[1],
        blue: hex[2]
      };
    }
    if (imageSrc && imageToCompareSrc)
    {
      return new Promise(resolve =>
      {
        resemble(imageSrc)
          .compareTo(imageToCompareSrc)
          .outputSettings(
            {
              errorColor: color,
            })
          .onComplete(data =>
          {
            if (!data.error)
            {
              resolve(data.getImageDataUrl());
            }
            else
            {
              resolve(undefined);
            }
          });
      });
    }
    else
    {
      throw new Error('Image source missing');
    }
  }

  hexToRgb(hex: string)
  {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
      , (m, r, g, b) => '#' + r + r + g + g + b + b)
      .substring(1).match(/.{2}/g)
      .map(x => parseInt(x, 16));
  }

  setOriginalBackupFile( originalFile: File)
  {
    this.backupUploadOriginalFile = originalFile;
  }

  setPrintedBackupFile( printedFile: File)
  {
    this.backupUploadPrintedFile = printedFile;
  }

  private delay(ms: number)
  {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }
}
