<div class="moon-pdf-viewer">
    <ng-container *ngIf="!pdfJsViewer">
        <div *ngIf="busy" class="spinner">
            <mat-progress-spinner  diameter="48" color="accent" mode="indeterminate"></mat-progress-spinner>
        </div>
        <pdf-viewer #pdfViewer class="pdf-viewer" [src]="pdfSrc" [ngClass]="{'hand-cursor': panOnClickDrag}"
            [render-text]="false"
            [original-size]="false"
            [zoom]="zoom"
            [show-borders]="false"
            [zoom-scale]="'page-fit'"
            [page]="page"
            [show-all]="showAllPages"
            (page-rendered)="onPageRendered($event)"
            (after-load-complete)="onLoadComplete($event)"
            (wheel)="onMouseWheel($event)"
            (mousemove)="onDrag($event, pdfViewer)"
            (mousedown)="onDragStarted()"
            (mouseup)="onDragEnded()"
            (mouseleave)="onDragEnded()">
        </pdf-viewer>
    </ng-container>

    <ng-container *ngIf="pdfJsViewer">
        @if (pdfSrc)
        {
            <div class="header">
                @if (document)
                {
                    @if (document.documentVersions?.length > 1)
                    {
                        <div class="version-selector pointer" [matMenuTriggerFor]="versionsMenu">
                            <span class="version-label">V{{selectedVersion}}</span>
                            <span class="notranslate material-symbols-outlined">arrow_drop_down</span>
                        </div>
                        <mat-menu #versionsMenu="matMenu">
                            @for (version of document.documentVersions; track version)
                            {
                                <button mat-menu-item (click)="setPdfFromMoonDocument(document, version.versionNumber)">
                                    <span class="version-button" [ngClass]="{'active': version.versionNumber === selectedVersion}">
                                        {{'lid.web2._shared.moon-pdf-viewer.version' | translate}} {{version.versionNumber}}
                                    </span>
                                </button>
                            }
                        </mat-menu>
                    }
                    @else
                    {
                        <div class="version-selector">
                            <span class="version-label">V{{selectedVersion}}</span>
                        </div>
                    }
                }

                <span class="pdf-name" [matTooltip]="pdfName">{{pdfName}}</span>
                <div class="actions">
                    <div class="search-input">
                        <input type="text" placeholder="Buscar texto" [(ngModel)]="searchText">
                        <span class="notranslate material-symbols-outlined">search</span>
                    </div>
                    @if (allowSrcChange)
                    {
                        <span class="close-button" (click)="pdfSrc = null">
                            <span class="notranslate material-symbols-outlined">close</span>
                        </span>
                    }
                </div>
            </div>
            <div class="pdf-viewer-container">
                <ngx-extended-pdf-viewer
                    [ngClass]="{'disableEditAnnotations' : commentMode !== commentModeEnum.INK }"
                    class="pdf-viewer" [src]="pdfSrc"
                    [showToolbar]="false"
                    [showOpenFileButton]="true"
                    [showZoomButtons]="true"
                    [showZoomDropdown]="true"
                    [showDrawEditor]="false"
                    [showStampEditor]="false"
                    [showTextEditor]="false"
                    [showHighlightEditor]="false"
                    [handTool]="false"
                    [showFindButton]="true"
                    [showPropertiesButton]="true"
                    [textLayer]="true"
                    [enableDragAndDrop]="true"
                    [(zoom)]="_zoom"
                    [page]="currentPage"
                    [rotation]="rotation"
                    [disableForms]="true"
                    [ignoreKeyboard]="true"
                    (currentZoomFactor)="onZoomFactorChange($event)"
                    (zoomChange)="onZoomChange($event)"
                    (pageRendered)="onPageRenderedPdfJs($event)"
                    (pageChange)="currentPage = $event"
                    (pdfLoaded)="onPdfLoaded()"
                    (textLayerRendered)="onTextLayerRendered($event)"
                    (updateFindState)="updateFindState($event)"
                    (annotationEditorEvent)="onAnnotationChange($event)">
                </ngx-extended-pdf-viewer>

                @if (showLayersSelector)
                {
                    <div class="layers-selector">
                        @if (layersBusy)
                        {
                            <div class="layers-busy">
                                <mat-progress-spinner diameter="32" color="accent" mode="indeterminate"></mat-progress-spinner>
                            </div>
                        }
                        @else
                        {
                            @for(layer of layers; track layer)
                            {
                                <div class="layer">
                                    <mat-checkbox (click)="toggleLayer(layer)" [checked]="layer.visible"></mat-checkbox>
                                    <span>{{layer.name}}</span>
                                </div>
                            }
                        }
                    </div>
                }


                <div class="right-toolbar">
                    <!-- <span class="toolbar-button alert-icon" *ngIf="pdfLoaded">
                        <mat-icon class="notranslate">warning</mat-icon>
                    </span>
                    <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}">
                        <mat-icon class="notranslate">high_quality</mat-icon>
                    </span> -->

                    <!-- @for (toolbarButton of extraToolbarButtons; track toolbarButton)
                    {
                        <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="toolbarButton.action()" [matTooltip]="toolbarButton.tooltip">
                            <mat-icon class="notranslate">{{toolbarButton.icon}}</mat-icon>
                        </span>
                    } -->


                    <span class="toolbar-button" (click)="showLayersSelector = !showLayersSelector" [ngClass]="{'disabled': !pdfLoaded || !layers || layers.length === 0}" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.layersSelector' | translate">
                        <mat-icon class="notranslate material-icons-outlined">layers</mat-icon>
                    </span>

                    <span class="divider"></span>

                    <span class="toolbar-input" [ngClass]="{'disabled': !pdfLoaded}" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.specificPage' | translate">
                        <input type="text" [value]="currentPage" (change)="onCurrentPageChange($event)" [disabled]="!pdfLoaded">
                    </span>

                    <span class="toolbar-label" [ngClass]="{'disabled': !pdfLoaded}" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.totalPages' | translate">
                        {{totalPagesCount}}
                    </span>
                    <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded || currentPage === 1}" (click)="goToPage(currentPage - 1)" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.previousPage' | translate">
                        <mat-icon class="notranslate material-icons-outlined">arrow_drop_up</mat-icon>
                    </span>
                    <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded || currentPage === totalPagesCount}" (click)="goToPage(currentPage + 1)" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.nextPage' | translate">
                        <mat-icon class="notranslate material-icons-outlined">arrow_drop_down</mat-icon>
                    </span>

                    <span class="divider"></span>

                    <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="zoomInPdfJs()" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.zoomIn' | translate">
                        <mat-icon class="notranslate material-icons-outlined">zoom_in</mat-icon>
                    </span>

                    <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="zoomOutPdfJs()" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.zoomOut' | translate">
                        <mat-icon class="notranslate material-icons-outlined">zoom_out</mat-icon>
                    </span>
                    <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="zoomPageFit()" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.fitPage' | translate">
                        <span class="notranslate material-symbols-outlined">fit_page_height</span>
                    </span>
                    <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="rotate()" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.rotate' | translate">
                        <mat-icon class="notranslate material-icons-outlined">refresh</mat-icon>
                    </span>

                    <span class="divider"></span>

                    @if (isSuperUser)
                    {
                        <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}" (click)="ocrPdfFile()" [matTooltip]="'lid.web2._shared.moon-pdf-viewer.ocr' | translate">
                            <span class="notranslate material-symbols-outlined">convert_to_text</span>
                        </span>
                    }

                    <!-- <span class="toolbar-button" [ngClass]="{'disabled': !pdfLoaded}">
                        <mat-icon class="notranslate material-icons-outlined">file_download</mat-icon>
                    </span> -->

                </div>

                <div class="left-toolbar" *ngIf="enableAnnotations">
                    <span class="toolbar-button" [ngClass]="{'active': commentMode === commentModeEnum.INK}" (click)="setCommentMode(commentModeEnum.INK)">
                        <span class="notranslate material-symbols-outlined">stylus_note</span>
                    </span>


                    <span class="toolbar-button" [ngClass]="{'active': commentMode === commentModeEnum.HIGHLIGHT}" (click)="setCommentMode(commentModeEnum.HIGHLIGHT)">
                      <span class="notranslate material-symbols-outlined">ink_highlighter</span>
                    </span>

                </div>

                @if (pdfLoaded)
                {
                    <div class="bottom-toolbar">
                        @if (customBottomToolbarButtons && customBottomToolbarButtons.length > 0)
                        {
                            @for (toolbarButton of customBottomToolbarButtons; track toolbarButton)
                            {
                                <span class="toolbar-text-button" [ngClass]="{'active': toolbarButton.isActive()}" (click)="toolbarButton.action()" [matTooltip]="toolbarButton.tooltip">
                                    @if (!toolbarButton.isBusy())
                                    {
                                        <span class="notranslate material-symbols-outlined">{{toolbarButton.icon}}</span>
                                        <span class="label">{{toolbarButton.label}}</span>
                                    }
                                    @else
                                    {
                                        <mat-progress-spinner diameter="16" color="accent" mode="indeterminate"></mat-progress-spinner>
                                    }
                                </span>
                            }
                        }
                        @else
                        {
                            <span class="toolbar-button"
                                [ngClass]="{'active': _viewerMode === pdfViewerModeEnum.Annotation}"
                                (click)="toggleViewerMode(pdfViewerModeEnum.Annotation)"
                                [moonTooltip]="adobeViewerTooltip"
                                [moonTooltipPosition]="'above'"
                                [moonTooltipTheme]="'dark'"
                                [moonTooltipGif]="'/assets/tutorial-gifs/adobemarks_demo.gif'">
                                <span class="notranslate material-symbols-outlined">add_comment</span>
                            </span>
                            <ng-template #adobeViewerTooltip>
                                <span translate>lid.web2._shared.moon-pdf-viewer.adobeViewerTooltip</span>
                            </ng-template>

                            <span class="toolbar-button"
                                [ngClass]="{'active': _viewerMode === pdfViewerModeEnum.Measurer}"
                                (click)="toggleViewerMode(pdfViewerModeEnum.Measurer)"
                                [moonTooltip]="measurementsTooltip"
                                [moonTooltipPosition]="'above'"
                                [moonTooltipTheme]="'dark'"
                                [moonTooltipGif]="'/assets/tutorial-gifs/measurement_demo.gif'">
                                <span class="notranslate material-symbols-outlined">straighten</span>
                            </span>
                            <ng-template #measurementsTooltip>
                                <span translate>lid.web2._shared.moon-pdf-viewer.measurementsTooltip</span>
                            </ng-template>

                            <span class="toolbar-button"
                                [ngClass]="{'active': _viewerMode === pdfViewerModeEnum.BarcodeReader}"
                                (click)="toggleViewerMode(pdfViewerModeEnum.BarcodeReader)"
                                [moonTooltip]="barcodeTooltip"
                                [moonTooltipPosition]="'above'"
                                [moonTooltipTheme]="'dark'"
                                [moonTooltipGif]="'/assets/tutorial-gifs/barcode_demo.gif'">
                                <span class="notranslate material-symbols-outlined">barcode_scanner</span>
                            </span>
                            <ng-template #barcodeTooltip>
                                <span translate>lid.web2.adobe-pdf-viewer.barcodeReader</span>
                            </ng-template>

                            <span class="divider"></span>

                            <span class="toolbar-button"
                                [ngClass]="{'active': _viewerMode === pdfViewerModeEnum.PixelCompare}"
                                (click)="toggleViewerMode(pdfViewerModeEnum.PixelCompare)"
                                [moonTooltip]="compareTooltip"
                                [moonTooltipPosition]="'above'"
                                [moonTooltipTheme]="'dark'"
                                [moonTooltipGif]="'/assets/tutorial-gifs/version_compare_demo.gif'">
                                <span class="notranslate material-symbols-outlined">compare</span>
                            </span>
                            <ng-template #compareTooltip>
                                <span translate>lid.web2._shared.moon-pdf-viewer.pixelCompareTooltip</span>
                            </ng-template>


                            <span class="toolbar-button"
                                (click)="toggleViewerMode(pdfViewerModeEnum.ImageCompare)"
                                [moonTooltip]="imageCompareTooltip"
                                [moonTooltipPosition]="'above'"
                                [moonTooltipTheme]="'dark'"
                                [moonTooltipGif]="'/assets/tutorial-gifs/image_compare_demo.gif'">
                                <span class="notranslate material-symbols-outlined">difference</span>
                            </span>
                            <ng-template #imageCompareTooltip>
                                <span translate>lid.web2._shared.moon-pdf-viewer.imageCompareTooltip</span>
                            </ng-template>

                            <span class="toolbar-button"
                                [ngClass]="{'active': _viewerMode === pdfViewerModeEnum.TextCompare}"
                                (click)="toggleViewerMode(pdfViewerModeEnum.TextCompare)"
                                [moonTooltip]="compareWithPdfTooltip"
                                [moonTooltipPosition]="'above'"
                                [moonTooltipTheme]="'dark'"
                                [moonTooltipGif]="'/assets/tutorial-gifs/text_compare_demo.gif'">
                                <span class="notranslate material-symbols-outlined">text_compare</span>
                            </span>
                            <ng-template #compareWithPdfTooltip>
                                <span translate>lid.web2._shared.moon-pdf-viewer.pdfCompareTooltip</span>
                            </ng-template>
                        }
                    </div>
                }
            </div>
        }
        @else
        {
            @if (downloadingFile)
            {
                <div class="busy">
                    <mat-progress-spinner diameter="48" color="accent" mode="indeterminate"></mat-progress-spinner>
                </div>
            }
            @else
            {
                <app-pdf-uploader class="pdf-viewer"
                    [optionalPdfFiles]="optionalPdfFiles"
                    [showVersionButton]="optionalMoonFile?.documentVersions?.length > 1"
                    (versionButtonClicked)="setPdfFromMoonDocument(optionalMoonFile, optionalMoonFile.latestMayorVersionNumber - 1)"
                    (fileUploaded)="onFileChange($event)"
                    (openMoonDocumentPicker)="onOpenMoonDocumentPicker()">
                </app-pdf-uploader>
            }
        }
    </ng-container>
</div>

