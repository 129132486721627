import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import {
        EventHubService,
        LoggingService,
        Severity
       } from '../../../../../../Packages/npm/moondesk-web/projects/moondesk-web-lib/src/public_api';
import { FeedbackNotificationsComponent } from './feedback-notifications/feedback-notifications.component';

export interface OnErrorAction
{
  buttonMessage: string;
  onClick: () => void;
}

@Injectable({
  providedIn: 'root'
})
export class FeedbackHelperService {

  constructor(private loggingService: LoggingService, private toastService: ToastrService, private eventHubService: EventHubService)
  {
  }

  async notifyFeedbackAdvanced(message: string,
                                messageType: 'soft warning' | 'successfull' | 'warning' | 'severity warning' = 'successfull',
                                error?: any,
                                action?: OnErrorAction)
    {
    if (error)
    {
      if (error.error)
      {
        message = `${message?.trim()}: ${error.error}`;
      }
      this.loggingService.logException(error, null, message);
      this.eventHubService.silentSupportMessage.emit({ message: message, error: error });
      this.loggingService.trackTrace(message, Severity.Error);
    }
    else
    {
      this.loggingService.trackTrace(message, Severity.Information);
    }
    let disableTimeout = false;
    switch (messageType)
    {
      case 'soft warning':
        {
          this.toastService.toastrConfig.timeOut = 4500;
          break;
        }
      case 'successfull':
        {
          this.toastService.toastrConfig.timeOut = 4000;
          break;
        }
      case 'warning':
        {
          this.toastService.toastrConfig.timeOut = 7000;
          break;
        }
      case 'severity warning':
        {
          this.toastService.toastrConfig.disableTimeOut = true;
          disableTimeout = true;
          break;
        }
    }
    if (this.toastService.toasts && this.toastService.toasts.length > 5)
    {
      this.toastService.remove(this.toastService.toasts[0].toastId);
    }
    this.toastService.toastrConfig.positionClass = 'toast-bottom-right';
    this.toastService.toastrConfig.maxOpened = 5;
    this.toastService.toastrConfig.autoDismiss = true;
    const activeToast = this.toastService.show(null, null, { toastComponent: FeedbackNotificationsComponent });
    const componentInstance = activeToast.toastRef.componentInstance;
    componentInstance['style'] = messageType;
    componentInstance['message'] = message;
    componentInstance['action'] = action;
    componentInstance['dismiss'] = () =>
    {
      this.toastService.remove(activeToast.toastId);
    };
    componentInstance['contactSupport'] = () =>
    {
      // @ts-ignore
      window.HubSpotConversations.widget.open();
      this.eventHubService.hubspotWidgedOpenStatus.emit(true);
    };
    if (!disableTimeout)
    {
      await this.delay(this.toastService.toastrConfig.timeOut);
      this.toastService.remove(activeToast.toastId);
    }
  }


  async delay(ms: number)
  {
  return new Promise(resolve => setTimeout(resolve, ms));
  }

}
