export interface TextDifference
{
  text: string;
  type: DiffType;

  originalText?: string;
}

export enum DiffType
{
  Equal,
  Inserted,
  Deleted,
}
