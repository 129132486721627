import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { GlobalTradeItem } from '../_models/barcodeQR/GlobalTradeItem';

@Injectable({
  providedIn: 'root'
})
export class BarcodeQRService
{

  constructor(private authService: AuthService)
  {}

  async getGlobalTradeItem(gtin: string): Promise<GlobalTradeItem>
  {
    let result: GlobalTradeItem;
    try
    {
      result = await this.authService.authGet<GlobalTradeItem>(
        `/api/v2/barcodeQR/GetGlobalTradeItem?gtin=${gtin}`
      );
      result.targetMarketList = result.targetMarket?.map(tm => tm.name).join(', ');
      result.octagonList = result.octagons?.filter(o => o.active).map(o => o.description).join(', ');
      result.netContent = result.netContent ? parseFloat(result.netContent).toString() : result.netContent;
      result.netContent = result.netContent === '0' ? '' : result.netContent;
      result.unitOfMeasure = result.unitOfMeasure?.replace(/\(.*?\)\s*/, '');
      result.package = result.package?.replace(/\(.*?\)\s*/, '');
    }
    catch (error)
    {
      if (error?.error !== 'GlobalTradeItemNotFound')
      {
        throw error; // GS1 connection error
      }
    }
    return result;
  }
}
